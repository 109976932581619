import { api } from "lib/api/base";
import {
  BaseApiResult,
  CardType,
  Reference,
  PickupLocation,
  Product,
  JobStatus,
  CheckListItem,
} from "types";
import { ENDPOINTS } from "lib/api/endpoints";

export type CardResponse = {
  card_type: CardType;
  reference: Reference;
  pickup_location: PickupLocation;
  products?: Product[];
  status?: JobStatus;
  allow_label_download?: boolean;
  asset_count?: string;
  checklist?: CheckListItem[];
} & BaseApiResult;

export const getCard = async (unique_id: string): Promise<CardResponse> => {
  const { data } = await api.post<CardResponse>(ENDPOINTS.card, {
    action: "get",
    unique_id,
  });

  return data;
};
