import { FC, ReactElement } from "react";
import Container from "react-bootstrap/Container";
import { Helmet } from "react-helmet";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Reference, PickupLocation, CheckListItem } from "types";

type ExpandedCheckListItem = CheckListItem & {
  number_image?: string;
  item_image?: string;
  complete_image?: ReactElement | string;
  button_variant?: string;
};

export type Props = {
  checklist: ExpandedCheckListItem[];
  reference: Reference;
  pickup_location: PickupLocation;
  asset_count: string;
};

const GTBStatus: FC<Props> = (props) => {
  for (var i = 0; i < props.checklist.length; i++) {
    props.checklist[i].number_image = "/images/number_" + (i + 1) + ".png";
    props.checklist[i].item_image =
      "/images/checklist_" + props.checklist[i].item_id + ".png";
    if (props.checklist[i].complete === "1") {
      props.checklist[i].complete_image = (
        <img alt="check" src="/images/check.png" />
      );
      props.checklist[i].button_variant = "secondary";
    } else {
      props.checklist[i].complete_image = "";
      props.checklist[i].button_variant = "primary";
    }
  }

  var items = props.checklist.map((item, index) => (
    <Card.Body key={index}>
      <Row className="image-row">
        <Col
          className="text-center"
          xs={{ span: 4, offset: 0 }}
          md={{ span: 3, offset: 2 }}
        >
          <img alt="item" src={item.number_image} />
        </Col>
        <Col
          className="text-center"
          xs={{ span: 4, offset: 0 }}
          md={{ span: 2, offset: 0 }}
        >
          <img alt="item" src={item.item_image} />
        </Col>
        <Col
          className="text-center"
          xs={{ span: 4, offset: 0 }}
          md={{ span: 3, offset: 0 }}
        >
          {item.complete_image}
        </Col>
      </Row>
      <Row>
        <Col className="text-center">{item.description}</Col>
      </Row>
      <Row className="button-row">
        <Col>
          <div className="btn-box">
            <Button href={item.url} variant={item.button_variant}>
              {item.action_text}
            </Button>
          </div>
        </Col>
      </Row>
      <hr />
    </Card.Body>
  ));

  return (
    <Container>
      <Helmet>
        <title>My Pickup</title>
      </Helmet>
      <Row>
        <Col md={{ offset: 2, span: 8 }} xs={12}>
          <Card>
            <Card.Body>
              <Card.Title className="text-center">
                <img
                  alt="truck"
                  src="/images/truck.png"
                  width="40"
                  className="title-truck"
                />{" "}
                My Pickup
              </Card.Title>
              <Card.Text>
                Dispatch ID: <strong>{props.reference.reference1}</strong>
                <br />
                <br />
                <strong>{props.asset_count} assets</strong> at your{" "}
                <strong>
                  {props.pickup_location.city}, {props.pickup_location.state}
                </strong>{" "}
                location.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>{items}</Card>
          <Card>
            <Card.Body>
              <img alt="logo" src="/images/logo.png" />
              <br />
              <a href="tel:8442448222">844-244-8222</a>
              <br />
              <a href="mailto:customerservice@onepak.com?subject=Feedback">
                customerservice@onepak.com
              </a>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <style>{`
        body {
          padding-top: 10px;
        }
        .title-truck {
          margin-right: 20px;
        }
        .image-row {
          margin-bottom: 30px;
        }
        .button-row {
          margin-top: 30px;
        }
        .btn-box {
          text-align: center;
          margin-bottom: 10px;
        }
      `}</style>
    </Container>
  );
};

export default GTBStatus;
