import { FC } from "react";
import Container from "react-bootstrap/Container";
import { Helmet } from "react-helmet";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Reference, PickupLocation, Product, JobStatus } from "types";

type ExtendedProduct = Product & {
  image?: string;
};

export type Props = {
  products: ExtendedProduct[];
  reference: Reference;
  status: JobStatus;
  allow_label_download: boolean;
  pickup_location: PickupLocation;
};

const ShippingKitStatus: FC<Props> = (props) => {
  var i;

  const portalURL = process.env.REACT_APP_PORTAL_BASE_URL;

  for (i = 0; i < props.products.length; i++) {
    props.products[i].image =
      "/images/product/product_" + props.products[i].product_id + ".png";
  }

  var mailto =
    "mailto:questions@dellreturncenter.com?subject=Question regarding order " +
    props.reference.reference1 +
    " / " +
    props.reference.job_id;

  var products = props.products.map((product, index) => (
    <Card.Body key={index}>
      <Row className="image-row">
        <Col
          className="text-center"
          xs={{ span: 5, offset: 0 }}
          md={{ span: 3, offset: 2 }}
        >
          <img src={product.image} alt="Product" className="product-image" />
        </Col>
        <Col
          className="text-left"
          xs={{ span: 6, offset: 0 }}
          md={{ span: 6, offset: 0 }}
        >
          <strong>{product.label}</strong>
          <br />
          Quantity: {product.quantity}
        </Col>
      </Row>
    </Card.Body>
  ));

  for (i = 0; i < props.status.steps.length; i++) {
    if (i === 0) {
      props.status.steps[i].class =
        "step-row step-row-top-" + props.status.steps[i].color;
    } else if (i === props.status.steps.length - 1) {
      props.status.steps[i].class =
        "step-row step-row-bottom-" + props.status.steps[i].color;
    } else {
      props.status.steps[i].class =
        "step-row step-row-middle-" + props.status.steps[i].color;
    }
    if (props.status.steps[i].tracking_number !== "") {
      props.status.steps[i].tracking_url =
        "http://wwwapps.ups.com/WebTracking/processInputRequest?InquiryNumber1=" +
        props.status.steps[i].tracking_number +
        "&AgreeToTermsAndConditions=yes&track.x=19&track.y=13";
    }
  }

  var steps = props.status.steps.map((step, index) => (
    <Row key={index}>
      <Col
        className={step.class}
        xs={{ span: 3, offset: 0 }}
        md={{ span: 3, offset: 1 }}
        lg={{ span: 3, offset: 2 }}
      ></Col>
      <Col
        className="text-left"
        xs={{ span: 8, offset: 0 }}
        md={{ span: 6, offset: 0 }}
        lg={{ span: 4, offset: 0 }}
      >
        {step.tracking_number !== "" ? (
          <strong>{step.name}:</strong>
        ) : (
          step.name + ":"
        )}
        <div className="status-details">
          {step.date !== "" ? "\n" + step.date : ""}
          {step.tracking_number !== "" ? (
            <span>
              <br />
              <a href={step.tracking_url} rel="noreferrer" target="_blank">
                {step.tracking_number}
              </a>
            </span>
          ) : (
            ""
          )}
        </div>
        <hr />
      </Col>
    </Row>
  ));

  var referenceRows = [
    props.reference.reference1_name.length > 0 && (
      <Row>
        <Col
          className="text-left"
          xs={{ span: 5, offset: 0 }}
          md={{ span: 4, offset: 1 }}
          lg={{ span: 4, offset: 1 }}
        >
          {props.reference.reference1_name}:
        </Col>
        <Col
          className="text-left"
          xs={{ span: 7, offset: 0 }}
          md={{ span: 6, offset: 0 }}
          lg={{ span: 6, offset: 0 }}
        >
          <strong>{props.reference.reference1}</strong>
        </Col>
      </Row>
    ),
    props.reference.reference2_name!.length > 0 && (
      <Row>
        <Col
          className="text-left"
          xs={{ span: 5, offset: 0 }}
          md={{ span: 4, offset: 1 }}
          lg={{ span: 4, offset: 1 }}
        >
          {props.reference.reference2_name}:
        </Col>
        <Col
          className="text-left"
          xs={{ span: 7, offset: 0 }}
          md={{ span: 6, offset: 0 }}
          lg={{ span: 6, offset: 0 }}
        >
          <strong>{props.reference.reference2}</strong>
        </Col>
      </Row>
    ),
    props.reference.user1_name!.length > 0 && (
      <Row>
        <Col
          className="text-left"
          xs={{ span: 5, offset: 0 }}
          md={{ span: 4, offset: 1 }}
          lg={{ span: 4, offset: 1 }}
        >
          {props.reference.user1_name}:
        </Col>
        <Col
          className="text-left"
          xs={{ span: 7, offset: 0 }}
          md={{ span: 6, offset: 0 }}
          lg={{ span: 6, offset: 0 }}
        >
          <strong>{props.reference.user1}</strong>
        </Col>
      </Row>
    ),
  ];

  var downloadlabelsCard = props.allow_label_download && (
    <Card>
      <Card.Body>
        <Card.Text>
          <Button
            type="submit"
            onClick={() => {
              if (typeof window !== "undefined") {
                window.location.href =
                  portalURL +
                  "download_label.php?job_id=" +
                  props.reference.job_id;
              }
            }}
          >
            Download Labels
          </Button>
        </Card.Text>
      </Card.Body>
    </Card>
  );
  return (
    <Container>
      <Helmet>
        <title>Shipping Kit Status</title>
      </Helmet>
      <Row>
        <Col md={{ offset: 2, span: 8 }} xs={12}>
          <Card>
            <Card.Body>
              <Card.Title className="text-left">
                Order Status
                <hr />
              </Card.Title>
              <Row>
                <Col
                  className="text-left"
                  xs={{ span: 5, offset: 0 }}
                  md={{ span: 6, offset: 0 }}
                  lg={{ span: 4, offset: 1 }}
                >
                  Pickup from:
                </Col>
                <Col
                  className="text-left"
                  xs={{ span: 6, offset: 0 }}
                  md={{ span: 6, offset: 0 }}
                  lg={{ span: 6, offset: 0 }}
                >
                  <strong>
                    {props.pickup_location.company},{" "}
                    {props.pickup_location.city}, {props.pickup_location.state}
                  </strong>
                </Col>
              </Row>
              <Card.Title className="text-center current-status">
                {props.status.current_status} - {props.status.date}
              </Card.Title>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Card.Title className="text-left">
                Status Details
                <hr />
              </Card.Title>
              {steps}
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Card.Title className="text-left">
                Order Details
                <hr />
              </Card.Title>
              <Row>
                <Col
                  className="text-left"
                  xs={{ span: 5, offset: 0 }}
                  md={{ span: 4, offset: 1 }}
                  lg={{ span: 4, offset: 1 }}
                >
                  Order Number:
                </Col>
                <Col
                  className="text-left"
                  xs={{ span: 7, offset: 0 }}
                  md={{ span: 6, offset: 0 }}
                  lg={{ span: 6, offset: 0 }}
                >
                  <strong>{props.reference.job_id}</strong>
                </Col>
              </Row>
              {referenceRows}
            </Card.Body>
            {products}
          </Card>
          {downloadlabelsCard}
          <Card>
            <Card.Body>
              <Card.Text>
                <strong>Dell Assets Resale and Recovery</strong>
                <br />
                <a href={mailto}>questions@dellreturncenter.com</a>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <style>{`
        body {
          padding-top: 10px;
        }
        .current-status {
          margin-top: 30px;
        }
        .product-image {
          width: 50%;
        }
        .status-details {
          font-size: 80%;
          margin-left: 10px;
        }
        .step-row {
          background-repeat: no-repeat;
          background-position: 70% top;
        }
        .step-row-top-gray {
          background-image: url("/images/circle_check_gray_top.png");
        }
        .step-row-middle-gray {
          background-image: url("/images/circle_check_gray_middle.png");
        }
        .step-row-bottom-gray {
          background-image: url("/images/circle_check_gray_bottom.png");
        }
        .step-row-top-green {
          background-image: url("/images/circle_check_green_top.png");
        }
        .step-row-middle-green {
          background-image: url("/images/circle_check_green_middle.png");
        }
        .step-row-bottom-green {
          background-image: url("/images/circle_check_green_bottom.png");
        }
        .btn {
          border-radius: 7px;
        }
      `}</style>
    </Container>
  );
};

export default ShippingKitStatus;
