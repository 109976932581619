import { BrowserRouter as Router, Route } from "react-router-dom";
import StatusPageContainer from "pages/StatusPage";
import ErrorPage from "pages/ErrorPage";

function App() {
  return (
    <Router>
      <Route path="/:unique_id">
        <StatusPageContainer />
      </Route>
      <Route exact path="/">
        <ErrorPage statusCode="Error: unique_id not provided." />
      </Route>
    </Router>
  );
}

export default App;
