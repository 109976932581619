import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { getCard, CardResponse } from "lib/api/getCard";
import { CardParams } from "types";
import { StatusPage } from "./StatusPage";

const StatusPageContainer: FC = () => {
  const [card, setCard] = React.useState<CardResponse | null>(null);
  const [error, setError] = React.useState<string>("");
  let { unique_id } = useParams<CardParams>();

  React.useEffect(() => {
    let mounted = true;
    const getCardData = async () => {
      try {
        const cardData = await getCard(unique_id);
        if (mounted) {
          setCard(cardData);
        }
      } catch (e: any) {
        setError(e.message);
      }
    };

    void getCardData();

    return () => {
      mounted = false;
    };
  }, [unique_id]);

  return <StatusPage card={card!} error={error} />;
};

export default StatusPageContainer;
