export interface BaseApiResult {
  error?: string;
  result: "Success" | "Failure";
}

export enum CardType {
  shipping_kit = "shipping_kit_job_status",
  gtb = "gtb_job_status",
}

export type Reference = {
  job_id: string;
  reference1: string;
  reference2?: string;
  reference1_name: string;
  reference2_name?: string;
  user1_name?: string;
  user1?: string;
};

export type PickupLocation = {
  company?: string;
  city: string;
  state: string;
};

export type Product = {
  product_id: string;
  label: string;
  quantity: string;
};

export type Step = {
  name: string;
  color: string;
  tracking_number: string;
  date: string;
  class?: string;
  tracking_url?: string;
};

export type JobStatus = {
  current_status: string;
  date: string;
  steps: Step[];
};

export type CheckListItem = {
  complete: string;
  item_id: string;
  url: string;
  description: string;
  action_text: string;
};

export type CardParams = {
  unique_id: string;
};
