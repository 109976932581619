import { FC } from "react";
import { CardResponse } from "lib/api/getCard";
import ShippingKitStatus from "components/ShippingKitStatus";
import { Spinner } from "components/Spinner";
import GTBStatus from "components/GTBStatus";
import { CardType } from "types";
import ErrorPage from "pages/ErrorPage";

export type Props = {
  card: CardResponse;
  error: string;
};

export const StatusPage: FC<Props> = ({ card, error }) => {
  if (error) {
    return <ErrorPage statusCode={error} />;
  }

  if (card) {
    switch (card.card_type) {
      case CardType.gtb:
        return (
          <GTBStatus
            reference={card.reference}
            pickup_location={card.pickup_location}
            asset_count={card.asset_count!}
            checklist={card.checklist!}
          />
        );

      case CardType.shipping_kit:
        return (
          <ShippingKitStatus
            reference={card.reference}
            pickup_location={card.pickup_location}
            products={card.products!}
            status={card.status!}
            allow_label_download={card.allow_label_download!}
          />
        );

      default:
        return (
          <ErrorPage
            statusCode={"Unrecognized card type (" + card.card_type + ")"}
          />
        );
    }
  } else {
    return <Spinner />;
  }
};
