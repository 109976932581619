import axios from "axios";

import { cacheAdapter } from "./cacheAdapter";

const API_KEY = process.env.REACT_APP_API_KEY || "";
const BASE_URL = process.env.REACT_APP_API_URL || "";

export const api = axios.create({
  adapter: cacheAdapter,
  auth: {
    password: "",
    username: API_KEY,
  },
  baseURL: BASE_URL,
  withCredentials: true,
});
