import { FC } from "react";
import styles from "./ErrorPage.module.scss";

export type Props = {
  statusCode: string;
};

const ErrorPage: FC<Props> = ({ statusCode }) => {
  return (
    <div className={styles.Outer}>
      <div>
        <h1>{statusCode}</h1>
        <div className={styles.Inner}>
          <h2>An unexpected error has occurred</h2>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
