import { FC } from "react";
import {
  Spinner as BootstrapSpinner,
  SpinnerProps as BootstrapSpinnerProps,
} from "react-bootstrap";

import styles from "./Spinner.module.scss";

type SpinnerProps = Partial<BootstrapSpinnerProps>;

export const Spinner: FC<SpinnerProps> = (props = {}) => (
  <div className={styles.Spinner}>
    <BootstrapSpinner animation="border" {...props} />
  </div>
);
